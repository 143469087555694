@import "../../scss/colors.scss";

.signIn {
  background-color: #c1e8bf;
  background-image: url("../../assets/mainBg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  padding: 0 16px 0 16px;

  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  &__inner {
    width: 100%;
    max-width: 516px;
    min-height: 200px;
    border-radius: 20px;
    padding: 0 100px;
    padding-top: 53px;
    padding-bottom: 117px;

    background: #ffffff;
    border-radius: 24;
  }

  &__logoWrapper {
    text-align: center;
    margin-bottom: 40px;
  }

  &__inputWrapper {
    margin-bottom: 12px;
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    color: $darkBlue;

    text-align: center;
    margin-bottom: 32px;
  }

  &__buttonWrapper {
    margin-top: 48px;
  }

  [class~="input"]:nth-of-type(1) {
    margin-bottom: 16px;
  }
}
