@import "../../scss/colors.scss";
@import "../../scss/repeat.scss";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  outline: none;
}

.input {
  position: relative;
  font-size: 14px;
  font-weight: 500;

  &-label {
    margin-bottom: 16px;
    font-size: 12px;
    color: $gray;
  }

  &Area {
    @extend %input;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 50px;
    padding: 0 18px;
    padding-left: 22px;
    background-color: $formControlBg;
    border-radius: 8px;
    border: 2px solid $formControlBg;

    font-size: 14px;
    font-weight: 500;

    &::placeholder {
      font-family: "Mulish";
      font-size: 14px;
    }

    &:focus {
      border: 2px solid #4dbd47;
      background-color: white;
    }
  }

  span.error {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: red;
  }
  &_error {
    border: 2px solid red;
  }

  &-error-text {
    color: red;
    font-size: 14px;
  }
}
