@import "../../scss/colors.scss";

.settings {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &_createAdmin {
    flex: 0.48;
    padding: 48px;

    &_h3 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
      color: $darkBlue;
    }

    &_inputWrapper {
      margin-bottom: 24px;
    }

    &_buttonWrapper {
      width: 200px;
      margin-top: 68px;
    }
  }

  &_adminList {
    flex: 0.48;
    padding: 48px;

    &_list {
      &Item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px 0;
        border-bottom: 1px solid #e4e4e4;

        &_email {
          font-size: 14px;
          color: #808191;
        }

        &_button {
          font-size: 14px;
        }
      }
    }
  }
}
