@import "../../scss/repeat.scss";
@import "../../scss/colors.scss";

.button {
  @extend %button;

  width: 100%;
  padding: 18px 32px;
  border-radius: 16px;
  background-color: $success;
  color: #ffffff;

  transition: all 0.2s ease;

  &_small {
    padding: 14px 24px;
    font-size: 14px;
  }

  &_outlined {
    background-color: transparent;
    color: $success;
    border: 2px solid $success;
  }

  &:disabled {
    opacity: 0.4;
  }
}
