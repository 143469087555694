@import "../../scss/colors.scss";

.textarea {
  min-height: 164px;
  width: 100%;
  max-width: 100%;
  background-color: $formControlBg;
  border: none;
  font-size: 14px;
  border-radius: 8px;
  padding: 16px 24px;
  resize: none;
  border: none;
  overflow: auto;
  outline: none;
  border: 2px solid $formControlBg;

  &:focus {
    border: 2px solid #4dbd47;
    background-color: white;
  }

  &_label {
    margin-bottom: 16px;
    font-size: 12px;
    color: $gray;
  }
}
