@import "../../scss/repeat.scss";
@import "../../scss/colors.scss";

.page {
  width: 100%;
  flex-direction: row;
  padding: 24px;

  &_title {
    margin-bottom: 48px;
  }

  &_item {
    padding: 20px;
    font-size: 24px;
    border-radius: 10px;
    border: 1px solid gray;
    margin-bottom: 10px;
  }
}
