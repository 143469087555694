@import "../../scss/colors.scss";

.verses {
  display: flex;
  justify-content: space-between;
  min-height: 100%;

  &_inputWrapper {
    display: none;
  }

  &__deleteCategoryButton {
    width: 200px;
    margin-top: 20px;
  }

  &__category {
    flex: 0.49;
    display: flex;
    flex-direction: column;
    padding: 48px 28px;
    border-radius: 24px;

    &_button {
      &_wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
      }

      width: 200px;
    }
  }

  &__post {
    flex: 0.49;
    display: flex;
    flex-direction: column;
    padding: 48px 28px;
    border-radius: 24px;

    &_preview {
      position: relative;
      overflow: hidden;
      margin-bottom: 16px;

      &_label {
        margin-bottom: 16px;
        font-size: 12px;
        color: $gray;
      }

      &_chooseOther {
        position: absolute;
        top: 20px;
        left: 20px;
        padding: 10px 20px;
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        border-radius: 20px;
        font-size: 16px;
        cursor: pointer;
      }
    }

    &_uploadBlock {
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
      color: #ffffff;
      background-color: #c4c4c4;
      border-radius: 12px;
      cursor: pointer;
    }

    &_inputWrapper {
      margin-bottom: 24px;
    }

    &_button {
      width: 200px;

      &_wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 16px;
      }
    }
  }

  &__selectWrapper {
    margin-bottom: 20px;

    &__name {
      font-size: 12px;
      color: $gray;
      margin-bottom: 16px;
    }
  }
}
