@import "../../scss/colors.scss";

.select {
  &_container {
    background-color: black;
  }
}

.label {
  margin-bottom: 16px;
  font-size: 12px;
  color: $gray;
}
