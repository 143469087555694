html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
}
a:focus {
  outline: none;
}

body {
  font-family: "Mulish";
  background-color: #fff;
  font-weight: 400;
  color: #000;
}

.container {
  width: 100%;
  max-width: 1240px;
  padding: 0 15px;
  margin: 0 auto;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 13;
}

.article_shadow {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 24px;
}

.ReactCollapse--collapse {
  transition: height 0.3s;
}

::placeholder {
  color: #b2b3bd;
}

.popup-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(179, 227, 176, 0.4);
}

.popup-content {
  width: 310px;
  padding: 48px 24px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 12px 54px rgba(179, 227, 176, 0.54);
}

.modal {
  &_title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 32px;
    text-align: center;
  }

  &_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      flex: 0.48;
    }
  }
}
