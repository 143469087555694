.filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  color: #b2b3bd;
  font-size: 14px;
  border-bottom: 1px solid #e4e4e4;

  &_item {
    flex: 1;
    display: flex;
    color: #b2b3bd;
    font-size: 14px;

    &_postDate {
      flex-basis: 10%;
    }

    &_postTitle {
      flex-basis: 40%;
      padding-left: 100px;
    }

    &_postCategory {
      flex-basis: 20%;
    }

    &_postSubcategory {
      flex: 20%;
    }
  }
}
