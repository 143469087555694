.checkbox {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #808191;
  border-radius: 4px;
  cursor: pointer;

  &_active {
    background: #4dbd47;
    border: 2px solid #4dbd47;
  }
}
