@import "../../scss/repeat.scss";
@import "../../scss/colors.scss";

.userManagment {
  padding: 38px 32px;
  padding-top: 20px;

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 38px;
  }

  table {
    display: flex;
    flex-direction: column;
    width: 100%;

    thead {
      tr {
        display: flex;
        justify-content: space-between;
        padding-bottom: 8px;
        font-size: 14px;
        border-bottom: 1px solid #e3e6ec;

        th {
          flex: 1;
          text-align: left;
          font-size: 14px;
          font-weight: normal;
          color: #b2b3bd;
        }
      }
    }

    tbody {
      tr {
        display: flex;
        justify-content: space-between;
        padding: 32px 0;
        border-bottom: 1px solid #e3e6ec;
        margin-bottom: 0;

        th {
          flex: 1;
          text-align: left;
          font-size: 14px;
          font-weight: 500;
          color: $darkBlue;
        }
      }
    }
  }

  &_thStatus {
    color: #eb5757 !important;
  }

  &_thDate {
    color: #5f75ee !important;
  }

  &_buttonContainer {
    display: flex;
    justify-content: center;
  }

  &_buttonWrapper {
    margin-top: 48px;
    width: 200px;
  }
}
