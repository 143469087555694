.notFound {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;

  text-transform: uppercase;
  font-weight: 700;
  font-size: 42px;
  color: #000;
}
