.item {
  display: flex;
  align-items: center;
  padding: 28px 0;

  &_main {
    flex-basis: 40%;
    display: flex;
    align-items: center;

    &_date {
      flex-basis: 10%;
      font-size: 14px;
      color: #5f75ee;
    }

    &_avatar {
      max-width: 96px;
      min-width: 80px;
      display: flex;
      justify-content: center;
      margin-right: 16px;

      &_default {
        width: 80px;
        height: 80px;
        background-color: #a0d7e7;
        border-radius: 6px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }

      &_img {
        max-height: 80px;
      }
    }

    &_info {
      &_title {
        margin-bottom: 4px;
        font-size: 16px;
        color: #11142d;
      }

      &_subtitle {
        display: table-cell;
        font-size: 14px;
        color: #808191;
        margin-bottom: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &_wrapper {
          display: table;
          table-layout: fixed;
          width: 95%;
        }
      }

      &_information {
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          vertical-align: baseline;
          margin-right: 4px;
          font-size: 10px;
          color: #808191;
        }

        &_arrowIcon {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: -7px;
          transition: transform 0.2s;
        }

        &_open {
          transform: rotate(180deg);
        }
      }
    }
  }

  &_category {
    flex-basis: 20%;
    &_wrapper {
      display: flex;
      align-items: center;
    }

    &_feel {
      width: 20px;
      height: 20px;
      background-color: #ffc633;
      margin-right: 8px;
      border-radius: 4px;
    }

    &_title {
      color: #11142d;
      font-size: 14px;
    }
  }

  &_subcategory {
    flex-basis: 20%;
    font-size: 14px;
    color: #808191;
  }

  &_actions {
    display: flex;

    &_item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      margin-right: 6px;
      border-radius: 50px;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }

  &_content {
    display: flex;
    justify-content: space-between;

    &_bg {
      flex: 0.49;

      p {
        margin-bottom: 16px;
        color: #808191;
        font-size: 12px;
      }

      &_img {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 400px;
        background-color: #c4c4c4;
        color: #ffffff;
        border-radius: 12px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        cursor: pointer;

        &_actions {
          margin-top: 20px;
          display: flex;

          &_btn {
            width: 20%;
            margin-right: 10px;
          }
        }
      }
    }

    &_info {
      flex: 0.49;

      &_inputWrapper {
        margin-bottom: 20px;
      }

      &_button {
        width: 50%;

        &Wrapper {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

.item_category_feel {
  &_Happy {
    background-color: #ffc633;
  }

  &_Mad {
    background-color: #ef130d;
  }

  &_Sad {
    background-color: #3597c8;
  }

  &_Worried {
    background-color: #f68306;
  }
}
