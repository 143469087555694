@import "../../scss/colors.scss";

.home {
  background-color: $skyBlue;

  &__box {
    display: flex;
    min-height: 100vh;
  }
  &__header {
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 0.3px;
    line-height: 30px;
    color: #252733;
    text-transform: uppercase;

    margin-bottom: 54px;
  }

  &__menuLogo {
    display: flex;
    align-items: center;

    font-weight: 600;
    font-size: 32px;
    color: $darkBlue;
    padding: 0 20px;
    padding-left: 0;
    margin-bottom: 48px;
    margin-left: 16px;
  }

  &__navList {
    display: flex;
    flex-direction: column;
  }

  &__logOut {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &_wrapper {
      display: flex;

      &_img {
        margin-right: 16px;
      }

      &_content {
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;

        &_admin {
          margin-bottom: 4px;
          color: $darkBlue;
        }

        &_logout {
          cursor: pointer;
          color: $gray;
        }
      }
    }
  }

  &__adminTools {
    color: #808191;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 16px;
    margin-left: 16px;
  }

  &__menu {
    width: 255px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: "transparent";
    padding: 30px 0;
    padding-left: 20px;
    transition: transform 0.2s ease;
    overflow: hidden;

    &Icon {
      position: relative;
      z-index: 2;
      cursor: pointer;
      display: none;
      width: 32px;
      height: 25px;
      border: none;
      flex-direction: column;
      justify-content: space-between;
      background-color: transparent;
      div {
        transition: all 200ms ease;
        height: 4px;
        border-radius: 4px;
        width: 100%;
        background-color: $darkBlue;
      }
      &_active {
        div {
          background-color: #fff;
        }

        div:nth-child(1) {
          transform: translate(0, 10.5px) rotate(45deg);
        }
        div:nth-child(2) {
          opacity: 0;
        }
        div:nth-child(3) {
          transform: translate(0, -10.5px) rotate(-45deg);
        }
      }
    }

    ul {
      flex: 1;

      li:last-of-type {
        position: relative;
        margin-top: auto;
      }
    }

    li {
      margin-bottom: 10px;
    }

    li a,
    li span {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 56px;
      width: 100%;
      padding: 0 16px;

      font-size: 16px;
      line-height: 20px;
      color: #808191;
      border-radius: 12px;

      transition: all 0.2s ease;
      & svg path {
        fill: #808191;
      }
      &:hover {
        color: #ffffff;
        background-color: $success;
        & svg {
          & path {
            opacity: 1;
            fill: #fff !important;
          }

          & circle {
            stroke: #fff !important;
          }
        }
      }

      &.active {
        color: #ffffff;
        background-color: $success;
        border-radius: 12px;
        & svg {
          & path {
            opacity: 1;
            fill: #fff !important;
          }

          & circle {
            stroke: #fff !important;
          }
        }
      }
      svg {
        margin-right: 12px;
        max-width: 16px;
        max-height: 16px;
        path {
          transition: all 0.2s ease;
          fill: #9fa2b4;
        }
      }
    }
  }

  &__right {
    width: calc(100% - 225px);
    max-height: 100vh;
    padding: 30px;
    overflow-y: auto;
  }
}
