@import "../../scss/repeat.scss";
@import "../../scss/colors.scss";

.contentManagment {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &_wrapper {
    width: 100%;
    padding: 32px;

    & h3 {
      margin-bottom: 48px;
    }
  }

  &_selectWrapper {
    margin-bottom: 25px;
  }

  &_contentList {
    &_button {
      width: 220px;
      &Wrapper {
        display: flex;
        justify-content: center;
      }
    }

    &_buttonWrapper {
      margin-top: 20px;
    }
  }
}
