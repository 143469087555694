/* mulish-regular - latin */
@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/mulish-v3-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/mulish-v3-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/mulish-v3-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/mulish-v3-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/mulish-v3-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/mulish-v3-latin-regular.svg#Mulish")
      format("svg"); /* Legacy iOS */
}
/* mulish-600 - latin */
@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/mulish-v3-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/mulish-v3-latin-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/mulish-v3-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/mulish-v3-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/mulish-v3-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/mulish-v3-latin-600.svg#Mulish")
      format("svg"); /* Legacy iOS */
}
/* mulish-700 - latin */
@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/mulish-v3-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/mulish-v3-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/mulish-v3-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/mulish-v3-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/mulish-v3-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/mulish-v3-latin-700.svg#Mulish")
      format("svg"); /* Legacy iOS */
}
