%button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

%input {
  outline: none;
  border: none;
}

%center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

%scrollList {
  overflow: hidden;
  &:hover {
    overflow-y: overlay;
  }
  &::-webkit-scrollbar {
    width: 10px;
    position: relative;
    z-index: 100;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    transition: background-color 4s linear;
    border-radius: 10px;
    background-color: #b9b9b9f8;
    &:active {
      background-color: #000;
    }
  }
}
